<template>
  <div class="container-intermidiate">
    <div class="main-card">
      <h4>{{ data }}</h4>
      <p>{{ content }}</p>
      <div class="vlt-btn submit-btns">
        <button class="btn-login" @click="actClicked">{{ data }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  props: ["data"],
  computed:{
    content(){
      if(this.data && (this.data.toLowerCase()==='subscribe')){
            return 'to Plan';
          }
      else return  'to watch the content';   
    }
  },
  methods: {
    actClicked() {
      // console.log("entered")
      EventBus.$emit("checkDisplayPayment", this.data);
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.container-intermidiate {
  width: 100%;
  font-family: $font-family;
  font-weight: $font-weight-regular;

  .main-card {
    width: 100%;
    background: $clr-color-black-2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $clr-color-black-3;
    border: 3px solid $clr-bg-gray-dark;
    border-radius: 12px;
    text-align: center;
    padding: 10% 5%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    h4 {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      color: $font-clr-white;
      margin-top: 25%;
      font-size: 1em;
    }
    p {
      // font-size: 12px;
      font-size: 0.8em;
    }
    .vlt-btn {
      width: 70%;
      margin-top: 30%;
      margin-bottom: 10%;
      background: none;
      .btn-login {
        // font-size: 10px;
        font-size: 0.7em;
      }
    }
  }
}
@include breakpoint(max768) {
  .btn-login {
    font-size: 0.5em !important;
  }
}
@include breakpoint(max600) {
  .main-card {
    h4 {
      font-size: 0.7em !important;
    }
  }
  .btn-login {
    font-size: 0.3em !important;
  }
}
</style>